// ProgressBar.js
import React from 'react';
import { useLocation } from 'react-router-dom';

const ProgressBar = () => {
  const location = useLocation();
  React.useEffect(() => {
    // Sayfa değiştikçe progress bar sıfırlanır
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return (
    <div className="progress-bar w-full h-1 bg-blue-500 fixed top-0 left-0 z-50" />
  );
};

export default ProgressBar;
